import axiosConfig, { ApiRequestType } from 'axios/axiosConfig';
import { DriverPointsData, OriginType, SanctioningBodyEnum } from 'models/viewModels';
import { StreamRequestType } from './RSocketObject';
import { RSocketStreamBaseService } from './RSocketStreamBaseService';

export class DriverPointsService extends RSocketStreamBaseService<DriverPointsData[], DriverPointsData> {
  protected async getStreamHistoryRequest(): Promise<DriverPointsData[]> {
    return axiosConfig(ApiRequestType.StreamMetricsApi)
      .post('/rest/backfill/driverPoints', this.request)
      .then((response) => response.data as DriverPointsData[])
      .catch(() => []);
  }

  protected onDataCallback = async (response: DriverPointsData) => {
    if (this.listeners.size > 0) {
      this.localCache = [...this.localCache.filter((item) => item.driverId !== response.driverId), response];
    }
  };

  async start() {
    this.delay = 5000;
    super.start();
  }
}

export const driverPointsRSocketService: Map<SanctioningBodyEnum, Map<OriginType, DriverPointsService>> = new Map(
  (Object.values(SanctioningBodyEnum) as SanctioningBodyEnum[])
    .filter((x) => typeof x === 'number')
    .map((sanctioningBody) => [
      sanctioningBody,
      new Map(
        (Object.values(OriginType) as OriginType[])
          .filter((x) => typeof x === 'number')
          .map((source: OriginType) => [
            source,
            new DriverPointsService({
              route: 'stream.driverPoints',
              connectionType: StreamRequestType.MotorsportsStreamApi,
              originType: source,
              sanctioningBody: sanctioningBody,
            }),
          ])
      ),
    ])
);
